import React, {useState} from "react"
import SearchPage from "components/pages/search"
import {DatePicker, Input} from "components/anti/forms/forms"
import { Button } from "components/anti/buttons/buttons"
import Layout from "components/layout";
import {Select} from "components/anti/forms/components/select";
import {NewsEventsList} from "components/pages/news-events/news-events-list";
import {graphql, useStaticQuery} from "gatsby";
import defaultImg from "assets/img/bg_default.jpg";
import {Card} from "components/anti/card/card";
import moment from "moment/moment";
import {Form, Formik} from "formik";
import * as Yup from "yup";

const Indeks = ({ pageContext, path }) => {
    const data = useStaticQuery(GET_INDEKS_PAGE)
    //
    const categories = data.wordPress.categories.nodes
    const posts = data.wordPress.posts.nodes

    const navLang = pageContext.langKey
    const lang = navLang.toUpperCase()

    const Options = categories
        .filter(data => data.slug !== "uncategorized")
        .map(data => {
            return { value: data.slug, label: data.name }
        })

    const [selectedCategory, setCategory] = useState(Options[0])
    const [filteredPost, setFilteredPost] = useState([])

    const initialValue = {
        category: "",
        startDate: "",
        endDate: "",
    }

    const formValidation = Yup.object({
        startDate: Yup.string().required(lang === "en" ? "Date start required" : "Tanggal awal wajib diisi"),
        endDate: Yup.string().required(lang === "en" ? "Date end required" : "Tanggal akhir wajib diisi"),
    })

    const filterPost = (values, { resetForm }) => {

        var dateStart = moment(values.startDate)
        var dateEnd = moment(values.endDate)

        console.log(values)

        setFilteredPost(
            posts
                .filter(post => ((post?.categories?.nodes[0].slug === selectedCategory.value) &&
                    (moment(post?.date).isBetween(dateStart, dateEnd))
                ))
        )
    }

    return (
        <Layout path={path} lang={lang}>
            <section className="pt-4 pb-main">
                <div className={`container mw-lg`}>
                    <Formik
                        onSubmit={filterPost}
                        validationSchema={formValidation}
                        initialValues={initialValue}>
                        {({ handleChange,
                              errors,
                              touched,
                              values,
                          }) => (
                          <Form>
                            <div className="form-row row-3">
                                <div className="form-group col-md-4">
                                    <Select
                                        variant="underline"
                                        id="locations"
                                        name="category"
                                        defaultValue={Options[0]}
                                        placeholder={lang === "en" ? `Choose Category` : "Pilih Kategori"}
                                        options={Options}
                                        onChange={(id, val) => setCategory(val)}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <DatePicker
                                        floatingLabel
                                        variant={"underline"}
                                        value={values["startDate"]}
                                        onChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                        label={lang === "en" ? "Start Date" : "Tanggal Mulai"}
                                        name="startDate"
                                        id="startDate"
                                        ></DatePicker>
                                </div>
                                <div className="form-group col-md-4">
                                    <DatePicker
                                        floatingLabel
                                        variant={"underline"}
                                        value={values["endDate"]}
                                        onChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                        label={lang === "en" ? "End Date" : "Tanggal Akhir"}
                                        name="endDate"
                                        id="endDate"
                                    ></DatePicker>
                                </div>
                            </div>
                            <div className="form-row text-center mt-4">
                                    <div className="form-group col-md-4 mx-auto">
                                        <Button
                                            variant="primary"
                                            className={`btn-block text-uppercase`}
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                          </Form>
                        )}
                    </Formik>


                    <div className="row">
                        {filteredPost.map((post, i) => {
                            const dataLang = lang === "en" ? post : post.translation || post
                            const image = dataLang?.featuredImage?.node?.sourceUrl
                                ? dataLang?.featuredImage?.node?.sourceUrl
                                : defaultImg
                            const label = post?.categories?.nodes[0]
                            const altText = dataLang?.featuredImage?.node?.altText
                            return (
                                <div className="col-md-4 mb px-0 px-md-4" key={i}>
                                    <Card
                                        variant="boxless"
                                        img={image}
                                        label={label?.name}
                                        title={dataLang?.title}
                                        imgAlt={altText ? altText : "Image"}
                                        titleClassName="text-truncate-twoline"
                                        labelClassName="text-truncate-twoline mb-2 font-weight-bold"
                                        imgRatio="r-4-3"
                                        cardBodyClassName="px-4 px-md-0 pt-2"
                                        link={`${lang === "en" ? "" : "/id"}/news-events/${
                                            post?.categories?.nodes[0].slug
                                        }/${post.slug}`}
                                    >
                                        <p className="text-muted mb-0">
                                            {moment(dataLang.date).format("DD MMM YYYY")}
                                        </p>
                                    </Card>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Indeks

const GET_INDEKS_PAGE = graphql`
query Get_Indeks_Page {
  wordPress {
    categories(where: {language: EN, orderby: DESCRIPTION}) {
      nodes {
        name
        slug
      }
    }
    posts(first: 1000, where: {language: EN}) {
      nodes {
        slug
        title
        date
        categories {
              nodes {
                name
                slug
              }
            }
        featuredImage {
          node {
            sourceUrl
          }
        }
        translation(language: ID) {
            title
            slug
            date
            categories {
              nodes {
                name
                slug
              }
            }
            featuredImage {
              node {
                sourceUrl
              }
            }
        }
      }
    }
  }
}
`